<template>
  <ckeditor
    :editor="editor"
    :value="content"
    :config="editorConfig"
    @input="onEditorChange"
    @ready="onReady"
  ></ckeditor>
</template>

<script>
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor.js";
//import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor.js";
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage.js";
import Autolink from "@ckeditor/ckeditor5-link/src/autolink.js";

//import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder.js";
//import CKFinderUploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js";
//import Code from "@ckeditor/ckeditor5-basic-styles/src/code.js";
//import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
//import ExportToPDF from "@ckeditor/ckeditor5-export-pdf/src/exportpdf.js";
//import ExportToWord from "@ckeditor/ckeditor5-export-word/src/exportword.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily.js";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight.js";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle.js";
//import Markdown from "@ckeditor/ckeditor5-markdown-gfm/src/markdown.js";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
import MediaEmbedToolbar from "@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js";
import Mention from "@ckeditor/ckeditor5-mention/src/mention.js";
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak.js";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat.js";
import SpecialCharacters from "@ckeditor/ckeditor5-special-characters/src/specialcharacters.js";
import SpecialCharactersArrows from "@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows.js";
import SpecialCharactersCurrency from "@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency.js";
import SpecialCharactersEssentials from "@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials.js";
import SpecialCharactersLatin from "@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin.js";
import SpecialCharactersMathematical from "@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical.js";
import SpecialCharactersText from "@ckeditor/ckeditor5-special-characters/src/specialcharacterstext.js";
//import StandardEditingMode from "@ckeditor/ckeditor5-restricted-editing/src/standardeditingmode.js";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript.js";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
//import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
//import Title from '@ckeditor/ckeditor5-heading/src/title.js';
//import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount.js";

import Language from "../../lang/translations/ckeditor/tr";
import UploadAdapter from "../../shared/utils/upload-adapter-helper";

import Ckeditor from "@ckeditor/ckeditor5-vue2";
//import "../../assets/ckeditor-theme/custom.css";
import CkeditorVideoUploadPlugin from "./CkeditorVideoUploadPlugin";

import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting.js";

export default {
  name: "TextEditor",
  props: {
    content: String,
  },
  components: {
    ckeditor: Ckeditor.component,
  },
  data() {
    return {
      editorLinks: [],
      editor: ClassicEditor,
      editorConfig: {
        mediaEmbed: {
          extraProviders: [
            {
              name: "zdy",
              url: [/^.*\.(mp4|MP4|mov|MOV|3gp|3GP|mpeg|MPEG)$/], //[/(.*?)/],
              html: (match) => {
                const src = match.input;
                return (
                  '<div style="position: relative;min-height:275px; max-height:500px; width:100%; padding:25px; pointer-events: auto; margin:auto;">' +
                  '<video controls style="position: absolute; width: 100%; height: 100%;min-height:275; max-height:500px;  margin:auto; top: 0; left: 0;" src="' +
                  src +
                  '">' +
                  "</video>" +
                  "</div>"
                );
              },
            },
          ],
        },
        allowedContent: true,
        extraAllowedContent: "section iframe video",
        extraPlugins: [this.uploader, CkeditorVideoUploadPlugin],
        plugins: [
          SourceEditing,
          CkeditorVideoUploadPlugin,
          Alignment,
          AutoImage,
          Autoformat,
          Autolink,
          BlockQuote,
          Bold,
          //CKFinder,
          //CKFinderUploadAdapter,
          //Code,
          //CodeBlock,
          Essentials,
          //ExportToPDF,
          //ExportToWord,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HorizontalLine,
          HtmlEmbed,
          Image,
          ImageCaption,
          ImageInsert,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListStyle,
          //Markdown,
          MediaEmbed,
          MediaEmbedToolbar,
          Mention,
          PageBreak,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          //StandardEditingMode,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCellProperties,
          TableProperties,
          TableToolbar,
          //TextTransformation,
          //Title,
          //TodoList,
          Underline,
          WordCount,
        ],
        toolbar: {
          items: [
            "sourceEditing",
            "ckeditorVideoUploadPlugin",
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            //"linkImage",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo",
            //"CKFinder",
            "alignment",
            //"code",
            //"exportPdf",
            //"codeBlock",
            "fontBackgroundColor",
            "fontSize",
            "htmlEmbed",
            "horizontalLine",
            "highlight",
            //"imageInsert",
            //"MathType",
            //"ChemType",
            "pageBreak",
            "removeFormat",
            //"restrictedEditingException",
            "specialCharacters",
            "strikethrough",
            "subscript",
            "superscript",
            //"todoList",
            "underline",
            //"exportWord",
            "fontColor",
            "fontFamily",
          ],
        },
        language: Language,
        image: {
          styles: ["alignLeft", "alignCenter", "alignRight"],
          resizeOptions: [
            {
              name: "imageResize:original",
              label: "Original",
              value: null,
            },
            {
              name: "imageResize:10",
              label: "10%",
              value: "10",
            },
            {
              name: "imageResize:25",
              label: "25%",
              value: "25",
            },
            {
              name: "imageResize:50",
              label: "50%",
              value: "50",
            },
            {
              name: "imageResize:75",
              label: "75%",
              value: "75",
            },
          ],
          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
            "imageStyle:alignRight",
            "imageStyle:alignCenter",
            "imageStyle:alignLeft",
            "|",
            "imageResize",
            "|",
            "imageTextAlternative",
            "linkImage",
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties",
          ],
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,
          // decorators: [
          //     {
          //         mode: 'manual',
          //         label: 'NoFollow',
          //         attributes: {
          //             rel: 'nofollow'
          //         }
          //     }
          // ]
        },
      },
    };
  },
  methods: {
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    onEditorChange(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style lang="scss">
.ck-content {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);
  max-height: 700px;
  min-height: 300px;
  display: flex;
  flex-flow: column nowrap;
}
</style>
