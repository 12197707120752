<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "app",
  created() {
    var lang = localStorage.getItem("lang");
    if (!lang) {
      lang = navigator.language;
    }
    lang = "tr-TR";
    localStorage.setItem("lang", lang);
    this.$i18n.locale = lang;
  },
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

@import "~vodal/common.css";
@import "~vodal/zoom.css";

/* Import Multiselect Vue Styles */
@import "~vue-multiselect/dist/vue-multiselect.min.css";

// Import Main styles for this application
@import "assets/scss/style";

@import "~vue-toastification/dist/index.css";
@import "~@voerro/vue-tagsinput/dist/style.css";

@import "~vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
</style>
