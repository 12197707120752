// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "./polyfill";
// import cssVars from 'css-vars-ponyfill'
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router/index";
import { ClientTable, ServerTable } from "vue-tables-2";
import Multiselect from "vue-multiselect";
import { Switch } from "@coreui/vue";
import VueMobileDetection from 'vue-mobile-detection'
import filters from "./shared/vue-filters";
import VueI18n from 'vue-i18n';
import messages from './lang';
import Vuelidate from 'vuelidate'
import Vodal from 'vodal';
import Toast from "vue-toastification";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import ToggleButton from 'vue-js-toggle-button'
import VoerroTagsInput from '@voerro/vue-tagsinput';
import FullScreen from 'vue-fullscreen';
import { store } from './store';
import CheckboxRadio from 'vue-checkbox-radio';
import AppSetting from '../AppSetting.json'
import Authorize from '../src/shared/authorization/authorize'
import TextEditor from '../src/widgets/form/TextEditor'

Vue.use(CheckboxRadio);
Vue.component("TextEditor", TextEditor);
Vue.component('tags-input', VoerroTagsInput);

Vue.use(ToggleButton)
Vue.use(FullScreen)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.use(Toast);
Vue.component(Vodal.name, Vodal);
Vue.use(BootstrapVue);
Vue.use(ClientTable);
Vue.use(ServerTable);
Vue.use(VueI18n);
Vue.use(Vuelidate)
Vue.use(VueMobileDetection)
Vue.component("multiselect", Multiselect);
Vue.component("c-switch", Switch);
Vue.filter("toTime", value => filters.getToTimeVueFilter(value));

export const i18n = new VueI18n({
  locale: 'tr-TR',
  fallbackLocale: 'tr-TR',
  messages,
  silentTranslationWarn: true
});

Vue.prototype.$appSetting = AppSetting
Vue.prototype.$auth = Authorize
// Vue.config.devtools = true
// Vue.config.performance = true
/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  i18n,
  template: "<App/>",
  components: {
    App
  }
});
