import { store } from '../../store'
export default class UploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject, file);
                this._sendRequest(file);
            }));
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        xhr.open('POST', process.env.VUE_APP_BASE_URL + "/api/Files/upload", true);
        xhr.setRequestHeader("Authorization", "Bearer " + localStorage.token);
        xhr.setRequestHeader("htmlContentImage", "true");
        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject, file) {
        try {
            document.getElementById("custom-loading-bar").style.display = "block";
            const xhr = this.xhr;
            const loader = this.loader;
            const genericErrorText = `Dosya yüklenemedi sunucu yanıt vermiyor: ${file.name}.`;
            xhr.addEventListener('error', (_) => {
                document.getElementById("custom-loading-bar").style.display = "none";
                return reject(genericErrorText);
            }
            );
            xhr.addEventListener('abort', () => {
                document.getElementById("custom-loading-bar").style.display = "none";
                return reject();

            });
            xhr.addEventListener('load', () => {
                document.getElementById("custom-loading-bar").style.display = "none";
                const response = xhr.response;
                if (!response || response.error || (response.statusCode && response.statusCode !== 200 && response.statusCode !== 204)) {
                    return reject(response && response.error ? response.error.message : genericErrorText);
                }

                store.state.uploadedFiles.push(response.data);
                resolve({
                    default: process.env.VUE_APP_CDN_URL + "/" + response.data.fileName
                });
            });

            if (xhr.upload) {
                xhr.upload.addEventListener('progress', evt => {
                    if (evt.lengthComputable) {
                        loader.uploadTotal = evt.total;
                        loader.uploaded = evt.loaded;
                    }
                });
            }
        } catch (error) {
            window.console.log(error);
            document.getElementById("custom-loading-bar").style.display = "none";
            return reject(error);
        }

    }

    // Prepares the data and sends the request.
    _sendRequest(file) {
        // Prepare the form data.
        const data = new FormData();
        // let img = new Image();
        // img.onload = () => {
        //     img.height = img.height / 2;
        //     img.width = img.width / 2;

        // };
        // img.src = URL.createObjectURL(file);

        data.append('file', file);

        // Send the request.
        this.xhr.send(data);
    }
}