import { createToastInterface } from "vue-toastification";

//Set configuration from here : https://maronato.github.io/vue-toastification/ 
const pluginOptions = {
    position: "top-center",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
};
const toast = createToastInterface(pluginOptions);
function success(message, title) {
    toast.success(message);
}

function info(message, title) {
    toast.info(message);
}

function warning(message, title) {
    toast.warning(message);
}

function error(message, title) {
    toast.error(message);
}
export default { success, info, warning, error }