import AuthBinding from './authorization-binding.json'
import { store } from '../../store';
function hasAuth(source, action) {
    if (typeof (source) == "function") return true;
    checkUserClaim();
    if (store.state.userClaims.get() === undefined) return true;

    const componentName = typeof (source) == "object" ? source.name : source;
    const claimId = AuthBinding[componentName];
    let checkClaim;
    if (claimId !== undefined) {
        checkClaim = store.state.userClaims.get().filter(p => p == claimId[action] || claimId[action] == -1);
        return checkClaim.length > 0;
    }
    else {
        console.log("Error:" + componentName + "." + action);
        return true;
    }
}
function checkUserClaim() {
    if (store.state.userClaims.get() === undefined) {
        window.location.href = "#/dashboard";
    }
}
export default { hasAuth }