import axios from "axios";

export const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + "/api/"
})

http.CancelToken = axios.CancelToken
http.isCancel = axios.isCancel

http.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token');
        let lang = localStorage.getItem("lang");
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
            config.headers['Accept-Language'] = lang;
        }
        config.headers['Pragma'] = "no-cache";
        config.headers['Cache-Control'] = "no-store, must-revalidate";
        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)