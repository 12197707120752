export function getCurrencyVueFilter(value) {
  if (typeof value !== "number") {
    return value;
  }

  var cultureName = localStorage.getItem("cultureName") ?? "tr-TR";
  var currencyCode = localStorage.getItem("currencyCode") ?? "TRY";
  var formatter = new Intl.NumberFormat(cultureName, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2
  });
  return formatter.format(value);
}

export function getToTimeVueFilter(value) {
  if (typeof value !== "string") {
    return value;
  }
  if (value.length != 19) return value;
  return value.substring(11, 16);
}

export default { getCurrencyVueFilter, getToTimeVueFilter };
