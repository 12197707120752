import Vue from "vue";
import Router from "vue-router";
import Authorize from "../shared/authorization/authorize";
import { getTranslation } from "../shared/language-helper";
// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");
// views
const Dashboard = () => import("@/views/Dashboard");

const CategoryAdd = () => import("@/views/category/CategoryAdd");
const CategoryList = () => import("@/views/category/CategoryList");

const NewsAdd = () => import("@/views/news/NewsAdd");
const NewsList = () => import("@/views/news/NewsList");
const NewsOrders = () => import("@/views/news/NewsOrders");
const CommentsList = () => import("@/views/news-comments/CommentList");

const NewsAgenciesList = () => import("@/views/news-agencies/NewsAgenciesList");

const TagAdd = () => import("@/views/tag/TagAdd");
const TagList = () => import("@/views/tag/TagList");

const TermAdd = () => import("@/views/term/TermAdd");
const TermList = () => import("@/views/term/TermList");

const MenuAdd = () => import("@/views/menu/MenuAdd");
const MenuList = () => import("@/views/menu/MenuList");

const AdsAdd = () => import("@/views/ads/AdsAdd");
const AdsList = () => import("@/views/ads/AdsList");

const AuthorList = () => import("@/views/author/AuthorList");
const AuthorAdd = () => import("@/views/author/AuthorAdd");

const PageList = () => import("@/views/pages/PageList");
const PageAdd = () => import("@/views/pages/PageAdd");

const UserList = () => import("@/views/user/UserList");
const UserAdd = () => import("@/views/user/UserAdd");
const UserProfile = () => import("@/views/user/UserProfile");

const Options = () => import("@/views/option/Options");

const Login = () => import("@/views/user/Login");
const Page404 = () => import("@/views/errorPages/Page404");

const ChangePassword = () => import("@/views/user/ChangePassword");
const ResetPassword = () => import("@/views/user/ResetPassword");
const ChangeLanguage = () => import("@/views/user/ChangeLanguage");

const LogList = () => import("@/views/log/LogList");
Vue.use(Router);
Vue.mixin({
  beforeRouteEnter(to, from, next) {
    // ***************************************** Check is authenticated
    if (localStorage.token === undefined && to.path != "/login") next("/login");
    // ***************************************** Check is authenticated
    if (to.meta.component === undefined) next(true);
    else {
      const component = to.meta.component.name;
      const action = to.meta.action;
      const authResult = Authorize.hasAuth(component, action);
      if (authResult) {
        next(authResult);
      } else {
        next("/dashboard");
      }
    }
  },
});
export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: getTranslation("login"),
      component: Login,
    },
    {
      path: "/reset-password",
      name: getTranslation("resetPassword"),
      component: ResetPassword,
    },
    {
      path: "",
      redirect: "/login",
    },
    {
      path: "/",
      redirect: "/dashboard",
      name: getTranslation("home"),
      component: DefaultContainer,
      children: [
        {
          path: "dashboard",
          component: Dashboard,
        },
        {
          path: "/news-add",
          name: getTranslation("newNews"),
          meta: {
            component: NewsList,
            action: "add",
          },
          component: NewsAdd,
        },
        {
          path: "news",
          name: getTranslation("newsManagement"),
          redirect: "/news-list",
          meta: {
            component: NewsList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/news-add/:id",
              name: getTranslation("updateNews"),
              meta: {
                component: NewsList,
                action: "edit",
              },
              component: NewsAdd,
            },
            {
              path: "/news-list",
              name: getTranslation("news"),
              meta: {
                component: NewsList,
                action: "list",
              },
              component: NewsList,
            },
          ],
        },
        {
          path: "news-agencies",
          name: getTranslation("newsAgenciesManagement"),
          redirect: "/news-agencies-list",
          meta: {
            component: NewsAgenciesList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/news-agencies-list",
              name: getTranslation("newsAgenciesList"),
              meta: {
                component: NewsAgenciesList,
                action: "list",
              },
              component: NewsAgenciesList,
            },
          ],
        },
        {
          path: "news-comments",
          name: getTranslation("newsCommentsManagement"),
          redirect: "/news-comments",
          meta: {
            component: CommentsList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/news-comments",
              name: getTranslation("newsComments"),
              meta: {
                component: CommentsList,
                action: "list",
              },
              component: CommentsList,
            },
            {
              path: "/news-comments/:id",
              name: getTranslation("newsComments"),
              meta: {
                component: CommentsList,
                action: "list",
              },
              component: CommentsList,
            },
          ],
        },
        {
          path: "news-orders",
          name: getTranslation("newsOrdersManagement"),
          redirect: "/news-orders",
          meta: {
            component: NewsOrders,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/news-orders",
              name: getTranslation("newsOrders"),
              meta: {
                component: NewsOrders,
                action: "list",
              },
              component: NewsOrders,
            },
          ],
        },
        {
          path: "category",
          name: getTranslation("categoryManagement"),
          redirect: "/category-list",
          meta: {
            component: CategoryList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/category-add",
              name: getTranslation("newCategory"),
              meta: {
                component: CategoryList,
                action: "add",
              },
              component: CategoryAdd,
            },
            {
              path: "/category-add/:id",
              name: getTranslation("updateCategory"),
              meta: {
                component: CategoryList,
                action: "edit",
              },
              component: CategoryAdd,
            },
            {
              path: "/category-list",
              name: getTranslation("categories"),
              meta: {
                component: CategoryList,
                action: "list",
              },
              component: CategoryList,
            },
          ],
        },
        {
          path: "author",
          name: getTranslation("authorManagement"),
          redirect: "/author-list",
          meta: {
            component: AuthorList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/author-add",
              name: getTranslation("newAuthor"),
              meta: {
                component: AuthorList,
                action: "add",
              },
              component: AuthorAdd,
            },
            {
              path: "/author-add/:id",
              name: getTranslation("updateAuthor"),
              meta: {
                component: AuthorList,
                action: "edit",
              },
              component: AuthorAdd,
            },
            {
              path: "/author-list",
              name: getTranslation("authors"),
              meta: {
                component: AuthorList,
                action: "list",
              },
              component: AuthorList,
            },
          ],
        },
        {
          path: "pages",
          name: getTranslation("pageManagement"),
          redirect: "/page-list",
          meta: {
            component: PageList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/page-add",
              name: getTranslation("newPage"),
              meta: {
                component: PageList,
                action: "add",
              },
              component: PageAdd,
            },
            {
              path: "/page-add/:id",
              name: getTranslation("updatePage"),
              meta: {
                component: PageList,
                action: "edit",
              },
              component: PageAdd,
            },
            {
              path: "/page-list",
              name: getTranslation("pages"),
              meta: {
                component: PageList,
                action: "list",
              },
              component: PageList,
            },
          ],
        },
        {
          path: "menu",
          name: getTranslation("menuManagement"),
          redirect: "/menu-list",
          meta: {
            component: MenuList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/menu-add",
              name: getTranslation("newMenu"),
              meta: {
                component: MenuList,
                action: "add",
              },
              component: MenuAdd,
            },
            {
              path: "/menu-add/:id",
              name: getTranslation("updateMenu"),
              meta: {
                component: MenuList,
                action: "edit",
              },
              component: MenuAdd,
            },
            {
              path: "/menu-list",
              name: getTranslation("menu"),
              meta: {
                component: MenuList,
                action: "list",
              },
              component: MenuList,
            },
          ],
        },
        {
          path: "ads",
          name: getTranslation("adsManagement"),
          redirect: "/ads-list",
          meta: {
            component: AdsList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/ads-add",
              name: getTranslation("newAds"),
              meta: {
                component: AdsList,
                action: "add",
              },
              component: AdsAdd,
            },
            {
              path: "/ads-add/:id",
              name: getTranslation("updateAds"),
              meta: {
                component: AdsList,
                action: "edit",
              },
              component: AdsAdd,
            },
            {
              path: "/ads-list",
              name: getTranslation("ads"),
              meta: {
                component: AdsList,
                action: "list",
              },
              component: AdsList,
            },
          ],
        },
        {
          path: "tag",
          name: getTranslation("tagManagement"),
          redirect: "/tag-list",
          meta: {
            component: TagList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/tag-add",
              name: getTranslation("newTag"),
              meta: {
                component: TagList,
                action: "add",
              },
              component: TagAdd,
            },
            {
              path: "/tag-add/:id",
              name: getTranslation("updateTag"),
              meta: {
                component: TagList,
                action: "edit",
              },
              component: TagAdd,
            },
            {
              path: "/tag-list",
              name: getTranslation("tags"),
              meta: {
                component: TagList,
                action: "list",
              },
              component: TagList,
            },
          ],
        },
        {
          path: "term",
          name: getTranslation("terms"),
          redirect: "/term-list",
          meta: {
            component: TermList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/term-add",
              name: getTranslation("newTerm"),
              meta: {
                component: TermList,
                action: "add",
              },
              component: TermAdd,
            },
            {
              path: "/term-add/:id",
              name: getTranslation("edit"),
              meta: {
                component: TermList,
                action: "edit",
              },
              component: TermAdd,
            },
            {
              path: "/term-list",
              name: getTranslation("terms"),
              meta: {
                component: TermList,
                action: "list",
              },
              component: TermList,
            },
          ],
        },
        {
          path: "user",
          name: getTranslation("userManagement"),
          redirect: "/user-list",
          meta: {
            component: UserList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/user-add",
              name: getTranslation("newUser"),
              meta: {
                component: UserList,
                action: "add",
              },
              component: UserAdd,
            },
            {
              path: "/user-add/:id",
              name: getTranslation("updateUser"),
              meta: {
                component: UserList,
                action: "edit",
              },
              component: UserAdd,
            },
            {
              path: "/user-list",
              name: getTranslation("user"),
              meta: {
                component: UserList,
                action: "list",
              },
              component: UserList,
            },
            {
              path: "/user-profile",
              name: getTranslation("userProfile"),
              meta: {
                component: UserProfile,
                action: "save",
              },
              component: UserProfile,
            },
          ],
        },
        {
          path: "report-list",
          redirect: "/log-list",
          name: getTranslation("reportManagement"),
          meta: {
            component: LogList,
            action: "list",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/log-list",
              name: getTranslation("logs"),
              meta: {
                component: LogList,
                action: "list",
              },
              component: LogList,
            },
          ],
        },
        {
          path: "/options",
          name: getTranslation("options"),
          meta: {
            component: Options,
            action: "save",
          },
          component: Options,
        },
        {
          path: "dashboard",
          redirect: "/",
          name: getTranslation("logs"),
          component: {
            render(c) {
              return c("router-view");
            },
          },
        },
        {
          path: "change-password",
          name: getTranslation("resetPassword"),
          component: ChangePassword,
        },
        {
          path: "change-language",
          name: getTranslation("changeLanguage"),
          component: ChangeLanguage,
        },
        {
          path: "*",
          name: getTranslation("pageNotFound"),
          component: Page404,
        },
      ],
    },
  ],
});
