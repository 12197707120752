
import { http } from "./http-base";
import toast from "./toast-helper";
import router from "../router/index.js";
function get(url, body, showLoading = true) {
  //if (showLoading) loadingAdd();
  return http
    .get(url, body)
    .then(response => {
      if (!response.data.success) {
        errorMessage(response.data);
        return;
      }
      return response.data.data;
    })
    .catch(e => {
      errorMessage(e.response);
    })
    .finally(() => {
     // if (showLoading) loadingRemove();
    });
}

function post(url, body, showLoading = true) {
  if (showLoading) loadingAdd();
  return http
    .post(url, body)
    .then(response => {
      if (!response.data.success) {
        errorMessage(response.data);
        return;
      }
      return response.data;
    })
    .catch(e => {
      errorMessage(e.response);
    })
    .finally(() => {
      if (showLoading) loadingRemove();
    });
}

function errorMessage(error) {
  if (error.status && error.status == 401) {
    logout();
  }
  if (error.status && error.status == 403) {
    logout();
  }
  if (error.data != "") {
    toast.error(error.data.message);
  }
  else {
    toast.error(error.statusText);
  }

}

function loadingAdd() {
  document.getElementById("showOverlay").style.display = "block";
}
function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("expiration");
  localStorage.removeItem("refreshToken");
  if (router.currentRoute.path != "/login") router.push({ path: "/login" });
}
function loadingRemove() {
  setTimeout(() => {
    document.getElementById("showOverlay").style.display = "none";
  }, 500);
}

export default { get, post, logout };
