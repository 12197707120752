import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        uploadedFiles: [],
        userClaims: {
            set(value) {
                localStorage.userClaims = value;
            },
            get() {
                return localStorage.userClaims?.split(",").map(p => parseInt(p));
            }
        }
    }
});