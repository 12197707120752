import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import icon from "@ckeditor/ckeditor5-core/theme/icons/upload_video_film_movie_icon.svg";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import http from "../../shared/http";
var editor = null;
class CkeditorVideoUploadPlugin extends Plugin {
  init() {
    editor = this.editor;

    editor.ui.componentFactory.add("ckeditorVideoUploadPlugin", (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: "Video Yükle",
        icon: icon,
        tooltip: true,
      });

      view.on("execute", (e, a) => {
        openDialogClick();
      });

      return view;
    });
  }
}

function openFileDialog(accept, multy = false, callback) {
  var inputElement = document.createElement("input");
  inputElement.type = "file";
  inputElement.accept = accept; // Note Edge does not support this attribute
  if (multy) {
    inputElement.multiple = multy;
  }
  if (typeof callback === "function") {
    inputElement.addEventListener("change", callback);
  }
  inputElement.dispatchEvent(new MouseEvent("click"));
}
function openDialogClick() {
  // open file dialog for text files
  openFileDialog(".mp4", true, fileDialogChanged);
}
function fileDialogChanged(event) {
  [...this.files].forEach((file) => {
    let formData = new FormData();
    formData.append("file", file);
    if (editor) {
      http
        .post("/Files/upload", formData)
        .then((response) => {
          var olData = editor.getData();
          if (!olData) {
            olData = "";
          }
          olData =
            olData +
            `<figure class="media"><oembed url="` +
            process.env.VUE_APP_CDN_URL +
            `/` +
            response.data.fileName +
            `"></oembed></figure>`;
          editor.setData(olData);
        })
        .catch((e) => {
          window.console.log(e);
        });
    }
  });
}
export default CkeditorVideoUploadPlugin;
